<template>
  <div class="v-stack h-stretch gap-3">
    <LogViewer :category="'project'" :reference="id"></LogViewer>
  </div>
</template>

<script>
import LogViewer from "@/components/LogViewer.vue";

export default {
  props: ["id"],
  components: {
    LogViewer,
  },
};
</script>
