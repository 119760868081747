<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light h-stretch">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <div v-if="logs.length == 0">No logs</div>
          <div v-for="log in logs" :key="log._id" class="v-stack h-stretch">
            <template v-if="log.category == 'project'">
              <div
                v-bind:class="[log.changes.length > 0 ? 'link' : '']"
                class="h-stack h-space-between"
                @click="expandLog(log)"
              >
                <div class="h-stack h-start gap-3">
                  <div>{{ formatDate(log.timestamp, "HH:mm DD.MM.YYYY") }}</div>
                  <div style="color: red">{{ log.userName }}</div>
                  <div>{{ log.message }}</div>
                  <div style="color: green">
                    {{ log.payload.name }}
                  </div>
                </div>
                <div v-if="log.changes.length > 0">
                  <template v-if="!log.expanded">&#9660;</template>
                  <template v-if="log.expanded">&#9650;</template>
                </div>
              </div>
              <div
                v-if="log.expanded && log.changes.length > 0"
                class="card h-stretch"
              >
                <div class="content h-stretch">
                  <div class="v-stack h-stretch gap-3">
                    <div
                      class="h-stack h-start gap-3"
                      v-for="change in log.changes"
                      :key="change._id"
                    >
                      <div>
                        {{ change.path ? change.path.join(" / ") : "no path" }}
                      </div>
                      :
                      <div>{{ change.oldValue || "added" }}</div>
                      =>
                      <div>{{ change.newValue || "removed" }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    category: {
      type: String,
      default: null,
    },
    reference: {
      type: String,
      default: null,
    },
  },
  watch: {
    category() {
      this.refresh();
    },
    reference() {
      this.refresh();
    },
  },
  data() {
    return {
      logs: [],
      regexp: new RegExp("^[0-9a-fA-F]{24}$"),
    };
  },
  methods: {
    ...mapActions(["getLogs", "getPricelistItem"]),
    expandLog(log) {
      log.expanded = !log.expanded;
      this.$forceUpdate();
    },
    formatDate(dateString, format) {
      const date = moment(dateString);
      return date.format(format);
    },
    refresh() {
      this.getLogs({
        category: this.category,
        reference: this.reference,
      })
        .then((logs) => {
          this.logs = logs;

          for (const log of this.logs) {
            log.expanded = false;
            if (log.payload.type == "pricelist") {
              for (const change of log.changes) {
                if (this.regexp.test(change.newValue)) {
                  this.getPricelistItem(change.newValue)
                    .then((item) => {
                      change.newValue = item.name;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                if (this.regexp.test(change.oldValue)) {
                  this.getPricelistItem(change.oldValue)
                    .then((item) => {
                      change.oldValue = item.name;
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
                for (const [index, path] of change.path.entries()) {
                  if (this.regexp.test(path)) {
                    this.getPricelistItem(path)
                      .then((item) => {
                        change.path[index] = item.name;
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
</style>